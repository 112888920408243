import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid';

import { Order } from '../../entities/order';
import OrderStatusBadge from './status-badge';
import { currency } from '../../services/formatter/currency';
import CommonAnimatedLoader from '../common/animated/loader';
import * as ordersService from '../../services/api/orders';
import { useAuth } from '../../hooks/use-auth';
import OrderImportStatusBadge from './import-status-badge';
import OrderReviewBadge from './review-badge';
import Badge from '../common/badge';

interface ComponentProps {
  orders: Order[];
  pages: number;
  onUpdate: (pageNo: number) => void;
  currPageNo: number;
}

export default function OrdersTableComponent({ orders, pages, onUpdate, currPageNo }: ComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { token } = useAuth();

  const onSendReminder = async (order: Order) => {
    setIsLoading(true);
    await ordersService.sendAccountCreationReminder(token, order.id);
    setIsLoading(false);
  };

  const onNextPage = () => {
    if (currPageNo < pages) {
      onUpdate(currPageNo + 1);
    }
  };

  const onPrevPage = () => {
    if (currPageNo > 0) {
      onUpdate(currPageNo - 1);
    }
  };

  const onPage = (page: number) => {
    onUpdate(page);
  };

  return (
    <>
      {isLoading && <CommonAnimatedLoader />}
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-4 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="relative px-3 py-3 w-32">
                      <span className="sr-only">Edit</span>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-24"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Customer
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Days left
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Import status
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Product
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Subtotal
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Discount
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Total
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Site
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Created at
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, index) => (
                    <tr key={order.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-3 py-4 text-left text-sm font-medium w-32 whitespace-pre-wrap">
                        <Link to={`/orders/results/${order.id}`} className="text-indigo-600 hover:text-indigo-900">
                          Edit
                        </Link>
                        {order.customerId ? (
                          <Link
                            to={`/messages/${order.customerId}`}
                            className="text-indigo-600 hover:text-indigo-900 pl-2"
                          >
                            Chat
                          </Link>
                        ) : (
                          <button
                            type="button"
                            onClick={() => onSendReminder(order)}
                            className="text-indigo-600 hover:text-indigo-900 underline"
                          >
                            Send Reminder
                          </button>
                        )}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900 w-24">
                        {order.id}
                        {!order.remoteId && (
                          <div>
                            <Badge status="danger" text="No Lis ID" />
                          </div>
                        )}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        {order.researchMemberId ? (
                          <div>DI{order.researchMemberId}</div>
                        ) : (
                          <div>
                            {order.firstName} {order.lastName}
                            <div className="text-xs">{order.email}</div>
                          </div>
                        )}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        <OrderStatusBadge status={order.status} />
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900 w-24">
                        {order.daysToDeadline > 0 && (
                          <Badge status="warning" text={`${order.daysToDeadline} day(s) left`} />
                        )}
                        {order.daysToDeadline === 0 && <Badge status="danger" text="URGENT" />}
                      </td>
                      <td className="px-4 py-4 text-sm text-gray-500">
                        {order.importStatus ? (
                          <OrderImportStatusBadge status={order.importStatus} />
                        ) : (
                          <OrderImportStatusBadge status="not completed" />
                        )}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-xs text-gray-500">
                        {order.productKey}{' '}
                        {order.isConsultation && order.productKey !== 'CONSULTATION' && '+ Consultation'}
                        {order.productId === 19 && 'PRO'}
                        {order.review && (
                          <div>
                            <OrderReviewBadge status={order.review} />
                          </div>
                        )}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        {currency(order.subtotal, order.currencyCode)}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        {currency(order.discount, order.currencyCode)}
                        {order.couponCode && <div className="text-xs">{order.couponCode}</div>}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        {currency(order.amount, order.currencyCode)}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{order.siteTitle}</td>
                      <td className="px-4 py-4 whitespace-nowrap text-xs text-gray-500">{order.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {pages > 1 && (
        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 pb-4">
          <div className="-mt-px flex w-0 flex-1">
            <button
              onClick={onPrevPage}
              type="button"
              id="first-page"
              disabled={currPageNo < 1 || currPageNo === 1}
              className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 enabled:hover:border-gray-300 enabled:hover:text-gray-700 disabled:opacity-50"
            >
              <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              Previous
            </button>
          </div>
          <div className="hidden md:-mt-px md:flex">
            {Array.from(Array(pages), (e, i) => {
              return (
                <button
                  type="button"
                  key={`page-${i + 1}`}
                  onClick={() => onPage(i + 1)}
                  className={`inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                    i + 1 === currPageNo && 'border-indigo-500 text-indigo-600'
                  } `}
                >
                  {i + 1}
                </button>
              );
            })}
          </div>
          <div className="-mt-px flex w-0 flex-1 justify-end">
            <button
              disabled={currPageNo === pages || currPageNo > pages}
              type="button"
              id="last-page"
              onClick={onNextPage}
              className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 enabled:hover:border-gray-300 enabled:hover:text-gray-700 disabled:opacity-50"
            >
              Next
              <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
          </div>
        </nav>
      )}
    </>
  );
}
