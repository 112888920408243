import axios from 'axios';

import { AuthToken } from '../../entities/auth-token';
import { parameters } from '../../constants/parameters';
import { Administrator } from '../../entities/administrator';
import { Allergist } from '../../entities/form/allergist';

export const login = async (email: string, password: string): Promise<AuthToken> => {
  const url = `${parameters.api.baseUrl}/users/admin/login`;

  const result = await axios.post(url, {
    email,
    password,
  });

  return {
    token: result.data.token,
    expiredAt: new Date(result.data.expiredAt),
  };
};

export const current = async (token: string): Promise<Administrator> => {
  const url = `${parameters.api.baseUrl}/users/admin/current`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const getAllergists = async (): Promise<Allergist[]> => {
  const params = {};
  const url = `${parameters.api.baseUrl}/users/admin/allergists-list`;

  const result = await axios.get(url, { headers: {}, params });

  return result.data;
};
