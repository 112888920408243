import React, { useEffect, useState } from 'react';
import { useParams, Outlet, NavLink, Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Select from 'react-select';

import CommonAnimatedLoader from '../../components/common/animated/loader';
import { useOrder } from '../../hooks/use-order';
import * as usersService from '../../services/api/users';
import * as ordersService from '../../services/api/orders';
import { useAuth } from '../../hooks/use-auth';
import OrderStatusBadge from '../../components/orders/status-badge';
import { OrderStatus } from '../../entities/order';
import CommonAnimatedSpinner from '../../components/common/animated/spinner';
import CommonConfirmationModal from '../../components/modals/confirmation';
import { Allergist } from '../../entities/form/allergist';
import CommonButtonDefault from '../../components/common/buttons/default';
import StyledError from '../../components/common/form/styled-error';
import IsAsitStatusBadge from '../../components/orders/is-asit-badge';

function OrdersResultsPageComponent() {
  const { id } = useParams();
  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSavingStatus, setIsSavingStatus] = useState<boolean>(false);
  const [isSavingMarkAsit, setIsSavingMarkAsit] = useState<boolean>(false);
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [isMarking, setIsMarking] = useState<boolean>(false);
  const [doctors, setDoctors] = useState<Allergist[]>();
  const [error, setError] = useState<string>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const location = useLocation();
  const { setOrderId, updateOrder, order } = useOrder();

  const tabs = [
    { name: 'Questionnaire', href: `/orders/results/${id}`, current: true },
    { name: 'Blood results', href: `/orders/results/${id}/blood`, current: false },
    { name: 'Allergens', href: `/orders/results/${id}/answer`, current: false },
    { name: 'Comments', href: `/orders/results/${id}/comments`, current: false },
    { name: 'Recommendations', href: `/orders/results/${id}/recommendations`, current: false },
  ];

  for (const tab of tabs) {
    tab.current = tab.href === location.pathname;
  }

  useEffect(() => {
    const fetchData = async () => {
      const allergists = await usersService.getAllergists();

      setDoctors(allergists);
    };

    if (id) {
      setOrderId(parseInt(id, 10));
      setIsLoading(false);
      fetchData();
    }
  }, [id, setIsLoading, setOrderId]);

  const onCompleteOrder = async () => {
    setIsConfirming(false);
    setIsSavingStatus(true);
    if (order) {
      await ordersService.complete(token, order?.order.id);

      updateOrder({
        ...order.order,
        status: OrderStatus.Completed,
      });
    }
    setIsSavingStatus(false);
  };

  const onMarkAsitOrder = async () => {
    setIsMarking(false);
    setIsSavingMarkAsit(true);
    if (order) {
      await ordersService.markAsit(token, order?.order.id);

      updateOrder({
        ...order.order,
        isAsit: true,
      });
    }
    setIsSavingMarkAsit(false);
  };

  const onSelectDoctor = async (event: any) => {
    if (id) {
      await ordersService.update(token, parseInt(id, 10), event.id);
    }
  };

  // console.log(order, token);
  const onSelectPreview = async (event: any) => {
    if (order) {
      const url = order?.frontendUrl || 'https://app.allergomedica.lt';

      // window.location.href = `${url}/results/redirect/${order.order.hash}/${token}`;
      window.open(`${url}/results/redirect/${order.order.hash}/${token}`, '_blank');
    } else {
      setIsLoading(false);
      setError('Order id or is missing');
    }
  };

  const downloadPdf = async () => {
    if (order?.order.pdfId) {
      try {
        setDisabled(true);
        const res = await ordersService.getPdf(token, order.order.pdfId);

        if (res) {
          setDisabled(false);
          window.location.href = res;
        }
      } catch (e) {
        setError((e as Error).message);
      }
    }
  };

  return (
    <div>
      {isLoading && <CommonAnimatedLoader />}
      {error && <StyledError title={error} />}
      {order && (
        <div>
          <div className="pb-5 pt-5 relative bg-white border-b border-gray-200 sm:pb-0">
            <div className="md:flex md:items-center md:justify-between  px-4 sm:px-6 lg:px-8">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Buyer:
                {order.order.researchMemberId
                  ? ` DI${order.order.researchMemberId}`
                  : ` ${order.order.firstName} ${order.order.lastName}`}{' '}
                (ID: {order.order.id})
                <span className="inline-flex items-center mx-4 px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                  {order.order.productKey}{' '}
                  {order.order.isConsultation && order.order.productKey !== 'CONSULTATION' && '+ Consultation'}
                </span>
                {order.order.phone}
              </h3>
              <div className="mt-3 flex space-x-3 md:mt-0 md:absolute md:top-3 md:right-4">
                <IsAsitStatusBadge status={order.order.isAsit} />
                {isSavingMarkAsit && (
                  <div className="ml-3 mt-2">
                    <CommonAnimatedSpinner sm />
                  </div>
                )}
                {!order.order.isAsit && (
                  <>
                    <button
                      type="button"
                      onClick={() => setIsMarking(true)}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Set for ASIT
                    </button>
                    <CommonConfirmationModal
                      open={isMarking}
                      confirmed={() => onMarkAsitOrder()}
                      cancelled={() => setIsMarking(false)}
                      title="Are you sure this order is suitable for ASIT?"
                      description="This order will be marked as suitable for ASIT."
                    />
                  </>
                )}
                <OrderStatusBadge status={order.order.status} />
                {isSavingStatus && (
                  <div className="ml-3 mt-2">
                    <CommonAnimatedSpinner sm />
                  </div>
                )}
                {order.order.status !== OrderStatus.Completed && (
                  <>
                    <button
                      type="button"
                      onClick={() => setIsConfirming(true)}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Complete order
                    </button>
                    <CommonConfirmationModal
                      open={isConfirming}
                      confirmed={() => onCompleteOrder()}
                      cancelled={() => setIsConfirming(false)}
                      title="Do you really want to complete order?"
                      description="Email will be sent to customer that result is ready for view. Complete order only when you wish to send an email to customer"
                    />
                  </>
                )}
                {order.order.customerId && (
                  <Link
                    to={`/messages/${order.order.customerId}`}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Chat
                  </Link>
                )}
                {order.order.productKey !== 'TREATMENT_PLAN' && (
                  <CommonButtonDefault
                    onClick={onSelectPreview}
                    className="inline-flex items-center no-underline px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Preview
                  </CommonButtonDefault>
                )}

                {order.order.pdfId && (
                  <div className="flex items-center">
                    <CommonButtonDefault className="text-sm" disabled={disabled} primary onClick={downloadPdf}>
                      Download PDF
                    </CommonButtonDefault>
                  </div>
                )}
                {doctors && (
                  <Select
                    className="w-56 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    options={doctors}
                    isClearable
                    defaultValue={doctors.filter(function (option) {
                      return option.id === order.order.administratorId;
                    })}
                    getOptionValue={(option: Allergist) => `${option.id}`}
                    getOptionLabel={(option: Allergist) => `${option.firstName} ${option.lastName}`}
                    onChange={(event) => onSelectDoctor(event)}
                  />
                )}
              </div>
            </div>

            <div className="mt-3 sm:mt-4 px-4 sm:px-6 lg:px-8">
              <div className="sm:hidden">
                <label htmlFor="current-tab" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="current-tab"
                  name="current-tab"
                  className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  defaultValue={tabs.find((tab) => tab.current)?.name}
                >
                  {order.order.productKey !== 'TREATMENT_PLAN' &&
                    tabs.map((tab) => <option key={`mobile-option-${tab.name}`}>{tab.name}</option>)}
                </select>
              </div>
              <div className="hidden sm:block">
                <nav className="-mb-px flex space-x-8">
                  {order.order.productKey !== 'TREATMENT_PLAN' &&
                    tabs.map((tab) => (
                      <NavLink
                        key={`tab-${tab.name}`}
                        to={tab.href}
                        className={classNames(
                          tab.current
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                          'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm no-underline',
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                      >
                        {tab.name}
                      </NavLink>
                    ))}
                </nav>
              </div>
            </div>
          </div>

          <div className="px-4 xxl:px-8 mt-8">
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
}

export default OrdersResultsPageComponent;
